import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './facebook.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faFacebook } from '@fortawesome/free-brands-svg-icons'; 

const Facebook = (props) => {                  

          return(
            <div className='facebook'>
<div className='iframe1'>
<iframe title='facebook' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpointonvh%2F&tabs=timeline&width=310&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2109644592532718" width="310" height="500" style={{border:'none', overflow:'hidden'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div>
<div className='iframe2'>
<iframe title='facebook' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fpointonvh%2F&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2109644592532718" width="500" height="500" style={{border:'none', overflow:'hidden'}} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div>
<p>Visit our page:</p>
<div className='fbsymbol'>
<a target='_blank' href='https://www.facebook.com/pointonvh/'>
      <FontAwesomeIcon className='facebook' size='4x' icon={faFacebook} color='#3b5998' />
      </a>
      </div>
        
        </div>

        )
    }

export default Facebook ;