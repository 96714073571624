import React, { useState, useEffect } from "react";
import {DiscussionEmbed} from "disqus-react"; 
import { NavLink } from 'react-router-dom';
import './home.css'

const Home = (props) => {  
  const disqusShortname = "villagehall-com"
  const disqusConfig = {
    url: "https://villagehall.com",
    identifier: 'article-id', // Single post id
    title: 'Title' // Single post title
  }                  

          return(
            <div className='home'>
              <p className='intro'>
                Welcome to the site for Pointon Village Hall. 
                Please see below information about the hall. 
                Elsewhere on the site, you will find our events calendar, Facebook feed and Instagram gallery. 
              </p>
              <h2>Need a spacious venue? </h2>
              <p className='intro'>
              The hall has a spacious 60' x 28' dance floor ideal for weddings, parties or dances and can accommodate 150 standing and 120 seated. 
               </p> 
               <img alt='hall' src='https://live.staticflickr.com/65535/52886191453_0907e56f4b.jpg'>
               </img> 
               <h2>Wilf's</h2>
               
<p className='intro'> 
On a Friday morning you can enjoy a cooked breakfast operating along side the Post Office Counter.
</p><p className='intro'> 
<b>
Friday - 9:30am to 11:30am
</b>
</p><p className='intro'> 
The catering facilities are also available during events upon request.
</p><p className='intro'>
  <b>
Free WiFi available.
</b> 
               </p>
               <h2>
               Feel the need to provide hospitality?
               </h2>
               <p className='intro'> 
               The public bar area can accommodate the most ardent of drinkers.
With a full alcohol licence the bar is available for functions and special events.

Alcoholic drink prices from £3.00 per pint
               </p>
               <img alt='bar' src='https://live.staticflickr.com/65535/52582048185_0feccff1aa_c.jpg'>
               </img>
               <h2>Fancy cooking up a storm in the kitchen?</h2>
               <p className='intro'> 
                Our 5 star hygiene rated and well resourced  kitchen can provide a convenient servery from which to dispense your function's catering. 
</p><p className='intro'> 
if required our in-house catering can provide a competitively priced and varied menu for your special function. </p>
              <img alt='kitchen' src='https://live.staticflickr.com/65535/52886191468_65d06e6ffd_z.jpg'></img>
              </div>


        )
    }

export default Home ;