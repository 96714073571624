import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './contact.css'

const Contact = (props) => {                  

          return(
            <div className='contact'>
         <h2>Contact Details:</h2>
          <p>Tel.: 07960 780457</p>
          <p>Email: bookings@pointonvillagehall.co.uk</p>
          <p>Address: 1 Millthorpe Road, Pointon, Sleaford, Lincolnshire, NG34 0NF</p>
          <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9633.201188826519!2d-0.3416559!3d52.8710061!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa3f24bf851c37ec4!2sPointon%20Village%20Hall!5e0!3m2!1sen!2suk!4v1671106365760!5m2!1sen!2suk" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
         </div>

        )
    }

export default Contact ;