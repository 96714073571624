import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './committee.css'

const Committee = (props) => {                  

          return(
            <div className='committee'>
         <h2>Committee Members:</h2>
         <iframe title='committee members' src="https://docs.google.com/document/d/e/2PACX-1vQdaPk3FuYi_QgXAFOAPxI0dTV8gqscSX0VS8i6Tk-fxGZRbjg1qrion8yGMUwtzctnfgvA3x318PwL/pub?embedded=true"></iframe>
         </div>

        )
    }

export default Committee ;