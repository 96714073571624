import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './calendar.css'

const Calendar = (props) => {                  

          return(
            <div className='calendar'>
                <h2>Clubs:</h2>
                <iframe className='clubs' src="https://docs.google.com/document/d/e/2PACX-1vSTPVrz8ALf8U8eBMg8Zf-b3jGLEgaboP6A8nv7sDd4dkqDMmoxws6c4ieEssRu25W-JaJo5o4KEm4e/pub?embedded=true"></iframe>
                <h2>Club Contacts:</h2>
                <iframe className='clubs contacts' src="https://docs.google.com/document/d/e/2PACX-1vT4z1DflP9tQLTIa0nfjPZFzId9D75oI5LaIypy8ti2C8RcX4GHidNLJKqn2AcXqXKXDjGgYRZGSufQ/pub?embedded=true"></iframe>
                 <h2>Calendar of events:</h2>
                <iframe title='google calendar' className='googleCalendar' src="https://calendar.google.com/calendar/embed?src=pointonvh%40gmail.com&ctz=Europe%2FLondon" style={{border: 0}} width="800" height="600" frameBorder="0" scrolling="no"></iframe>
                   </div>

        )
    }

export default Calendar ;