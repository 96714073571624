import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import './header.css'; 

const Header = () => {
  const [display, setDisplay] = useState(false);

  const changeDisplay = () => {
    if(display){setDisplay(false)}
    else if(!display){setDisplay(true)}
  }

          return(
              <div className='header'>
                <div className='heading'>
               <img className='hall-img' alt='village hall' src='https://live.staticflickr.com/65535/52885910064_09e265cf09_z.jpg'></img>
               <div className='heading-text'>
               <h1>Pointon Village Hall</h1> 
               <p>Charity No.: 521860</p>
               </div>
             </div>
             <div className='navbar'>
                <button onClick={(e) => {changeDisplay()}}>Navbar</button>
                {display &&
                  <div className='NavLinks'>
                <div className='NavLinkDiv'>
               <NavLink to='/' className='NavLink'>Home</NavLink> 
              </div>
              <div className='NavLinkDiv'>
               <NavLink to='/calendar' className='NavLink'>Clubs/Calendar</NavLink> 
              </div>
              <div className='NavLinkDiv'>
               <NavLink to='/facebook' className='NavLink'>Facebook</NavLink>
               </div>
               <div className='NavLinkDiv'>
               <NavLink to='/instagram' className='NavLink'>Instagram Gallery</NavLink>
               </div> 
               <div className='NavLinkDiv'>
               <NavLink to='/committee' className='NavLink'>Committee</NavLink>
               </div>           
              <div className='NavLinkDiv'>
               <NavLink to='/contact' className='NavLink'>Contact Details & Map</NavLink>
               </div>
               </div>
               }
              </div> 
              </div>

        )
    }

export default Header ;